<template>
  <div class="carousel-container">
    <div
      class="carousel-slides"
      :style="{ transform: `translateX(-${currentSlide * 100}%)` }"
    >
      <div
        class="carousel-slide"
        v-for="(slide, index) in data.carousel_cards"
        :key="index"
        :style="{
          backgroundImage: `url(${slide.imageUrl})`, // Use the imageUrl property of the slide
        }"
      >
        <div class="slide-content">
          <h2>{{ slide.title }}</h2>
          <p>{{ slide.description }}</p>
          <a :href="slide.link" target="_blank">
            <button class="action-btn">{{ slide.buttonName }}</button>
          </a>
        </div>
      </div>
    </div>

    <!-- Navigation buttons -->
    <button class="carousel-btn prev" @click="prevSlide">‹</button>
    <button class="carousel-btn next" @click="nextSlide">›</button>

    <!-- Dots for current slide -->
    <div class="carousel-dots">
      <span
        v-for="(slide, index) in data.carousel_cards"
        :key="index"
        class="dot"
        :class="{ active: currentSlide === index }"
        @click="goToSlide(index)"
      ></span>
    </div>
  </div>
</template>

<script>
export default {
  props: ["data"],
  data() {
    return {
      currentSlide: 0,
    };
  },
  mounted() {
    this.autoScroll();
  },
  methods: {
    nextSlide() {
      this.currentSlide =
        (this.currentSlide + 1) % this.data.carousel_cards.length;
    },
    prevSlide() {
      this.currentSlide =
        (this.currentSlide - 1 + this.data.carousel_cards.length) %
        this.data.carousel_cards.length;
    },
    goToSlide(index) {
      this.currentSlide = index;
    },
    autoScroll() {
      setInterval(() => {
        this.nextSlide();
      }, 4000); // Auto scroll every 4 seconds
    },
  },
};
</script>

<style scoped>
.carousel-container {
  position: relative;
  max-width: 100%;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 10px;
  height: 100%;
}

.carousel-slides {
  display: flex;
  transition: transform 0.5s ease-in-out;
  height: 90%;
}

.carousel-slide {
  min-width: 100%;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.slide-content {
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 20px;
  border-radius: 10px;
}

h2 {
  font-size: 2rem;
  margin-bottom: 10px;
}

p {
  font-size: 1.2rem;
}

.action-btn {
  background-color: var(--lighter-primary-color);
  border: none;
  color: white;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.action-btn:hover {
  background-color: var(--darker-primary-color);
}

/* Navigation buttons */
.carousel-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: slategrey;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 24px;
  border-radius: 50%;
  z-index: 2;
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}

/* Dots for current slide */
.carousel-dots {
  text-align: center;
  margin-top: 15px;
}

.dot {
  height: 12px;
  width: 12px;
  margin: 0 5px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.dot.active {
  background-color: var(--lighter-primary-color);
}

.carousel-btn:hover,
.dot:hover {
  background-color: slategray;
}

/* Responsive Design */
@media (max-width: 768px) {
  .carousel-slide {
    height: 200px;
  }

  h2 {
    font-size: 1.5rem;
  }

  p {
    font-size: 1rem;
  }

  .action-btn {
    font-size: 0.8rem;
    padding: 8px 16px;
  }
}

@media (max-width: 480px) {
  .carousel-slide {
    height: 150px;
  }

  h2 {
    font-size: 1.2rem;
  }

  p {
    font-size: 0.8rem;
  }

  .action-btn {
    font-size: 0.7rem;
    padding: 6px 12px;
  }
}
</style>
